import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: props.itemsShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
  };
  return (
    <Slider {...settings}>
      {props.carouselItems.map((val, i) => (
        <>
          {/* <img src={val} alt={i} style={{ maxWidth: '100%' }} /> */}
          <div
            className="testimage"
            style={{
              background: `url(${val})`,
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
          ></div>
        </>
      ))}
    </Slider>
  );
};

export default ImageCarousel;
